<template>
  <div class="requests" v-if="requests">
    <div class="container">
      <base-heading class="dashboard-heading" bold="bold">
        {{ $t("author-requests.heading") }}
      </base-heading>

      <base-table :columns="columns" :data="requests">
        <template slot-scope="{ row, index }">
          <td>{{ index + 1 }}.</td>
          <td>{{ row.firstName }} {{ row.lastName }}</td>
          <td>
            {{ formatDate(row.promotionSentAt) }}
          </td>
          <td>
            <base-button
              :to="`/requests/${row.id}`"
              :title="$t('author-requests.showRequest')"
              small
            >
              {{ $t("author-requests.showRequest") }}
            </base-button>
          </td>
        </template>
      </base-table>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import admin from '@/api/services/admin';

export default {
  name: 'Requests',
  data: () => ({
    requests: null,
  }),
  computed: {
    columns() {
      return this.$t('author-requests.columns').map((column, index) => ({
        key: index,
        label: column,
      }));
    },
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format('DD/MM/YYYY');
    },
  },
  async created() {
    this.$store.dispatch('ui/setPreloader', true);

    try {
      const { data } = await admin.getInstructorRequests();
      this.requests = data;
    } catch (err) {
      this.$store.dispatch('notification/spawn', {
        errorMessage: err.response.data.message,
        type: 'error',
      });
      this.requests = {};
    } finally {
      this.$store.dispatch('ui/setPreloader', false);
    }
  },
};
</script>

<style lang="scss" src="./Requests.scss" scoped />
